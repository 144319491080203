import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  AddApplicationsAdmin,
  AddMembers,
  DeleteApplicationsAdmin,
  DeleteMemberFromGroup,
  GetApplicationsAdmin,
  GetMembers,
} from '../actions/members.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Member } from '../models/member.model';
import { GroupService } from 'src/app/core/services/group.service';

export class MembersStateModel {
  members: Member[];
}

@State<MembersStateModel>({
  name: 'members',
  defaults: {
    members: [],
  },
})
@Injectable()
export class membersState {
  constructor(private groupService: GroupService) {}

  @Selector()
  static getMembersList(state: MembersStateModel) {
    return state.members;
  }

  @Action(GetMembers)
  getMembers(
    { getState, setState }: StateContext<MembersStateModel>,
    { groupName }: GetMembers
  ) {
    return this.groupService.getGroupMembers(groupName).pipe(
      tap((result) => {
        const state = getState();
        console.log('members', result);
        setState({
          ...state,

          members: result,
        });
      })
    );
  }

  @Action(AddMembers)
  addMembers(
    { getState, setState }: StateContext<MembersStateModel>,
    { groupName, payload }: AddMembers
  ) {
    return this.groupService.addMemberToGroup(groupName, payload).pipe(
      tap(() => {
        const state = getState();
        setState({
          ...state,
          members: [...state.members, payload],
        });
      })
    );
  }
  @Action(DeleteMemberFromGroup)
  deleteMemberFromGroup(
    { getState, setState }: StateContext<MembersStateModel>,
    { memberEmail, groupName }: DeleteMemberFromGroup
  ) {
    return this.groupService.deleteMemberFromGroup(memberEmail, groupName).pipe(
      tap(() => {
        const state = getState();
        console.log('state', state);

        const filteredArray = state.members.filter(
          (item) => item.memberEmail !== memberEmail
        );
        setState({
          ...state,
          members: filteredArray,
        });
      })
    );
  }
}
