import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Group } from 'src/app/shared/models/group.model';
import { Member } from 'src/app/shared/models/member.model';
import { SubscriptionModel } from 'src/app/shared/models/subscription.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  ApiUrl = '';
  constructor(private httpClient: HttpClient) {
    this.ApiUrl = environment.ApiUrl + '/group';
  }

  getGroupsByapplication(applicationName: string) {
    return this.httpClient.get<Group[]>(
      this.ApiUrl + '/application/' + applicationName
    );
  }

  assignGroupToChannel(applicationName: string, payload: SubscriptionModel) {
    return this.httpClient.put<SubscriptionModel>(
      environment.ApiUrl + '/subscriptions/' + applicationName,
      payload
    );
  }
  getGroupsBychannels(applicationName: string) {
    return this.httpClient.get<SubscriptionModel[]>(
      environment.ApiUrl + '/subscriptions/' + applicationName
    );
  }

  getGroupMembers(groupName: string) {
    return this.httpClient.get<Member[]>(this.ApiUrl + '/' + groupName);
  }

  addMemberToGroup(groupName: string, payload: Member) {
    return this.httpClient.put<Group>(this.ApiUrl + '/' + groupName, payload);
  }

  addUpdateGroup(applicationName: string, payload: Group) {
    return this.httpClient.put<Group>(
      this.ApiUrl + '/application/' + applicationName,
      payload
    );
  }

  deleteGroup(groupName: string) {
    return this.httpClient.delete(this.ApiUrl + '/' + groupName);
  }

  deleteGroupAssignment(channelName: string, groupName: string) {
    return this.httpClient.delete(
      environment.ApiUrl +
        '/subscriptions/group/' +
        groupName +
        '/channel/' +
        channelName
    );
  }

  deleteMemberFromGroup(userName: string, groupName: string) {
    return this.httpClient.delete(
      this.ApiUrl + '/' + groupName + '/groupMembers/' + userName
    );
  }




}
